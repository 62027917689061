import React, { useEffect, useState, useCallback } from 'react'
import './index.css'
import { faCircleExclamation, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from "react-router-dom";
import logo from './images/sas.webp'
import axios from './axiosInstance'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'

const App = () => {
  const [dob, setDob] = useState('')
  const [name, setName] = useState('')
  const [age, setAge] = useState('')
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const [bmi, setBmi] = useState()
  const [bmiCategory, setBmiCategory] = useState('')
  const [waistCategory, setWaistCategory] = useState('')
  const [heightUnit, setHeightUnit] = useState('cm')
  const [weightUnit, setWeightUnit] = useState('kg')
  const [bloodPressure, setBloodPressure] = useState('')
  const [pulse, setPulse] = useState('')
  const [blood, setBlood] = useState('')
  const [glucose, setGlucose] = useState('')
  const [additionalResults, setAdditionalResults] = useState('')
  const [cholesterol, setCholesterol] = useState('')
  const [weeklyUnitIntake, setWeeklyUnitIntake] = useState('')
  const [weeklyHours, setWeeklyHours] = useState('')
  const [smokerStatus, setSmokerStatus] = useState('')
  const [additionalNotes, setAdditionalNotes] = useState('')
  const [email, setEmail] = useState('')
  const [optOut, setOptOut] = useState(false)
  const navigate = useNavigate();

  const calculateBmi = useCallback(() => {
    let heightInMeters = height
    let weightInKg = weight

    if (heightUnit === 'feet') {
      heightInMeters = height * 0.3048
    } else {
      heightInMeters = height / 100
    }

    if (weightUnit === 'stone') {
      weightInKg = weight * 6.35029
    }

    const calculatedBmi = weightInKg / (heightInMeters * heightInMeters)
    setBmi(calculatedBmi.toFixed(1))
    if (calculatedBmi < 18.5) {
      setBmiCategory('underweight')
    } else if (calculatedBmi >= 18.5 && calculatedBmi <= 24.9) {
      setBmiCategory('healthy')
    } else if (calculatedBmi >= 25 && calculatedBmi <= 29.9) {
      setBmiCategory('overweight')
    } else if (calculatedBmi >= 30 && calculatedBmi <= 34.9) {
      setBmiCategory('obese')
    } else {
      setBmiCategory('veryObese')
    }
  }, [height, weight, heightUnit, weightUnit])

  const saveFormData = async (formData) => {
    const isOnline = () => {
      return navigator.onLine
    }

    if (isOnline()) {
      try {
        await axios.post('health-screen-report/create', formData)

        Swal.fire({
          title: 'Health Screen Complete!',
          text: 'Your health screen results have been submitted, you will receive an email with a copy of your results.',
          icon: 'success',
        }).then(() => {
          window.location.reload()
        })
      } catch (error) {
        Swal.fire({
          title: 'Error Occured!',
          text: 'There was an error submitting your results, please try again',
          icon: 'error',
        })
        console.error('Error saving data:', error)
      }
    } else {
      await saveDataLocally(formData)
      console.log('Data saved locally')
    }
  }

  const saveDataLocally = async (formData) => {
    try {
      const existingData = localStorage.getItem('healthScreenData');
      let newData = existingData ? JSON.parse(existingData) : [];
      
      newData.push(formData);

      Swal.fire({
        title: 'Health Screen Complete!',
        text: "Your health screen results have been submitted. Unfortunately we don't have any internet at the minute you will receive an email with a copy of your results when we get back online.",
        icon: 'success',
      }).then(() => {
        window.location.reload()
      })
      localStorage.setItem('healthScreenData', JSON.stringify(newData))
    } catch (error) {
      console.error('Error saving data locally:', error)
    }
  }

  const syncData = useCallback(async () => {
    console.log('here')
    try {
      const isOnline = () => {
        return navigator.onLine
      }
      // Retrieve the locally saved data
      const savedData = localStorage.getItem('healthScreenData')
      if (savedData && isOnline()) {
        const formData = JSON.parse(savedData)
        formData.forEach(async (data) => {
          console.log(data)
          // Submit the saved data when online
          await axios.post('health-screen-report/create', data)
        })

        localStorage.removeItem('healthScreenData')
      }
    } catch (error) {
      console.error('Error syncing data:', error)
    }
  }, [])

  useEffect(() => {
    syncData()
  }, [syncData])

  useEffect(() => {
    if (height && weight) {
      calculateBmi()
    }
  }, [height, weight, heightUnit, weightUnit, calculateBmi])

  const handleClick = (bmiCategory, waistCategory) => {
    setBmiCategory(bmiCategory)
    setWaistCategory(waistCategory)
  }

  const toggleHeightUnit = () => {
    if (heightUnit === 'cm') {
      setHeightUnit('feet')
      setHeight((prevHeight) => (prevHeight / 30.48).toFixed(2))
    } else {
      setHeightUnit('cm')
      setHeight((prevHeight) => (prevHeight * 30.48).toFixed(0))
    }
  }

  const toggleWeightUnit = () => {
    if (weightUnit === 'kg') {
      setWeightUnit('stone')
      setWeight((prevWeight) => (prevWeight / 6.35029).toFixed(2))
    } else {
      setWeightUnit('kg')
      setWeight((prevWeight) => (prevWeight * 6.35029).toFixed(0))
    }
  }

  const handleDobChange = (e) => {
    setDob(e.target.value)
    const birthDate = new Date(e.target.value)
    const currentDate = new Date()
    let age = currentDate.getFullYear() - birthDate.getFullYear()
    const monthDifference = currentDate.getMonth() - birthDate.getMonth()
    const dayDifference = currentDate.getDate() - birthDate.getDate()

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--
    }

    setAge(age)
  }

  const allInputsFilled = name && height && weight

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!name || !dob || !email) {
      toast.error('Please fill in all the required fields.')
      return
    }

    const formData = {
      name,
      dob,
      age,
      height,
      weight,
      heightUnit,
      weightUnit,
      bmi,
      bmiCategory,
      waistCategory,
      bloodPressure,
      pulse,
      blood,
      glucose,
      additionalResults,
      cholesterol,
      weeklyUnitIntake,
      weeklyHours,
      smokerStatus,
      additionalNotes,
      email,
      optOut,
    }

    await saveFormData(formData)
  }

  return (
    <div className='relative'>
      <button 
        className="absolute top-0 right-0" 
        onClick={() => navigate('/login')}
      >
        <FontAwesomeIcon 
          icon={faRightToBracket} 
          className="text-sas-primary text-2xl mr-2 mt-2 hover:text-blue-700" 
        />
      </button>
      <div className=" min-h-screen max-w-4xl  mx-auto">
        <div className="flex items-center">
          <div className="text-xl md:text-4xl font-bold md:mb-8 bg-sas-primary py-10 w-3/4 rounded-r-full">
            <h1 className="pl-6 md:pl-16 text-white font-fatfrank">
              Health Screening Report
            </h1>
          </div>
          <div className="mb-5">
            <img
              src={logo}
              alt="schools advisory service logo"
              className="overflow-hidden w-64"
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="w-full py-6 flex flex-col justify-start relative z-10">
            <div className="mb-4 grid md:grid-cols-3 gap-4 px-4">
              <div>
                <label
                  className="block font-fatfrank text-sas-primary md:text-lg font-bold"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  autoComplete="off"
                />
              </div>
              <div>
                <label
                  className="block font-fatfrank text-sas-primary md:text-lg font-bold"
                  htmlFor="dob"
                >
                  Date of Birth
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="dob"
                  type="date"
                  name="dob"
                  placeholder="DD/MM/YYYY"
                  value={dob}
                  onChange={handleDobChange}
                  required
                  autoComplete="off"
                />
              </div>
              <div>
                <label
                  className="block font-fatfrank text-sas-primary md:text-lg font-bold"
                  htmlFor="age"
                >
                  Age
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="age"
                  type="text"
                  name="age"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  readOnly
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4 md:mt-4 px-4">
              <div>
                <label
                  className="block font-fatfrank text-sas-primary md:text-lg font-bold"
                  htmlFor="height"
                >
                  Height
                </label>
                <div className="pb-4">
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-lg ${
                      heightUnit === 'cm' ? 'bg-sas-primary' : 'bg-gray-400'
                    } mr-2 text-white font-fatfrank text-sm`}
                    onClick={() => toggleHeightUnit('cm')}
                  >
                    Metric
                  </button>
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-lg ${
                      heightUnit === 'feet' ? 'bg-sas-primary' : 'bg-gray-400'
                    } text-white font-fatfrank text-sm`}
                    onClick={() => toggleHeightUnit('feet')}
                  >
                    Imperial
                  </button>
                </div>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="height"
                  type="number"
                  name="height"
                  placeholder={heightUnit === 'cm' ? '163cm' : '5.35 feet'}
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div>
                <label
                  className="block font-fatfrank text-sas-primary md:text-lg font-bold"
                  htmlFor="weight"
                >
                  Weight
                </label>
                <div className="pb-4">
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-lg ${
                      weightUnit === 'kg' ? 'bg-sas-primary' : 'bg-gray-400'
                    } mr-2 text-white font-fatfrank text-sm`}
                    onClick={() => toggleWeightUnit('kg')}
                  >
                    Kilograms
                  </button>
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-lg ${
                      weightUnit === 'stone' ? 'bg-sas-primary' : 'bg-gray-400'
                    } text-white font-fatfrank text-sm`}
                    onClick={() => toggleWeightUnit('stone')}
                  >
                    Stone
                  </button>
                </div>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="weight"
                  type="number"
                  name="weight"
                  placeholder={weightUnit === 'kg' ? '70kg' : '11.02 stone'}
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="px-4">
              <h2 className="mt-4 font-fatfrank text-sas-primary text-lg md:text-2xl">
                Body Mass Index
              </h2>
              <div className="text-sm md:text-base mt-1 rounded-lg p-4 bg-sas-primary-hover text-sas-primary-dark font-montserrat px-4">
                BMI is an indicator of overall weight and body fat which is
                calculated by dividing a person’s weight in kilograms by the
                square of their height in metres. Waist circumference measurement
                provides an indicator of abdominal weight. Both these measurements
                can provide relevant information for health care professionals
                that may support an individual’s health goals.
              </div>
            </div>
            {allInputsFilled && (
              <div className="my-4 md:mt-10">
                <div className="bg-sas-primary-dark rounded-t-[110px] text-white text-center space-y-4 font-montserrat">
                  <div className="pt-4 md:pt-8 text-sm md:text-lg">
                    Your BMI is{' '}
                  </div>
                  <div className="text-3xl md:text-4xl font-bold font-fatfrank">
                    {bmi || 'N/A'}
                  </div>
                  <div className="pb-4 md:pb-10 text-xs md:text-base px-4 md:px-8">
                    {bmi < 18.5 && (
                      <p>
                        A BMI of {bmi} indicates you are underweight for your
                        height and age. Bringing your weight up to a healthy level
                        will help reduce your risk of developing health problems.
                      </p>
                    )}
                    {bmi >= 18.5 && bmi <= 24.9 && (
                      <p>
                        A BMI between 18.5 - 24.9 indicates you are a healthy
                        weight for your height and age. Maintaining a healthy
                        weight will lower your risk of developing weight-related
                        health problems, like type 2 diabetes or heart disease.
                      </p>
                    )}
                    {bmi >= 25 && bmi <= 29.9 && (
                      <p>
                        A BMI between 25 - 29.9 indicates you are overweight for
                        your height and age. Reaching a healthy weight will lower
                        your risk of developing weight-related health problems.
                      </p>
                    )}
                    {bmi >= 30 && bmi <= 34.9 && (
                      <p>
                        A BMI between 30 - 34.9 indicates you are obese for your
                        height and age. Reaching a healthy weight will lower your
                        risk of developing weight-related health problems. Note
                        that individuals with a high muscle mass, such as
                        athletes, may have an elevated BMI due to increased muscle
                        weight. Therefore, BMI may overestimate body fat in these
                        cases.
                      </p>
                    )}
                    {bmi > 34.9 && (
                      <p>
                        A BMI greater than 34.9 indicates you are very obese for
                        your height and age. Reaching a healthy weight will
                        significantly lower your risk of developing weight-related
                        health problems.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="relative overflow-hidden mt-8 font-montserrat pb-4 px-4">
              {/* This is the half-circle shape */}
              <div className="absolute bottom-0 right-0 w-1/2 h-96 flex items-center justify-center overflow-hidden z-0">
                <div className="w-full h-full bg-sas-primary rounded-tl-full"></div>
              </div>
              <div className="relative grid grid-cols-4 gap-1 md:gap-2 z-10">
                {/* BMI Header */}
                <div className="col-span-1 bg-blue-500 text-white text-center p-2 md:p-4 rounded-lg flex items-center justify-center">
                  <h2 className="font-bold text-xl md:text-3xl font-fatfrank">
                    BMI
                  </h2>
                </div>
                {/* Waist Circumference Header */}
                <div className="col-span-3 grid grid-cols-3 gap-1 md:gap-2">
                  <div className="text-center p-2 md:p-4 flex items-center justify-center col-span-3 font-fatfrank border border-sas-primary-dark rounded-lg bg-white">
                    <h2 className="font-bold text-sm md:text-lg tracking-wide text-sas-primary-dark">
                      Waist Circumference
                    </h2>
                  </div>
                  <div className="text-center bg-blue-200 rounded-lg flex items-center justify-center font-medium text-sas-primary-dark text-xs md:text-base p-2 md:p-4">
                    <p>
                      Men: &lt; 94cm
                      <br />
                      Women: &lt; 80cm
                    </p>
                  </div>
                  <div className="text-center bg-blue-300 rounded-lg flex items-center justify-center font-medium text-sas-primary-dark text-xs md:text-base p-2 md:p-4">
                    <p>
                      Men: 94 - 102cm
                      <br />
                      Women: 80 - 88cm
                    </p>
                  </div>
                  <div className="text-center bg-blue-400 text-white rounded-lg flex items-center justify-center font-medium text-xs md:text-base p-2 md:p-4">
                    <p>
                      Men: &gt; 102cm
                      <br />
                      Women: &gt; 88cm
                    </p>
                  </div>
                </div>
              </div>

              {/* BMI Rows */}
              <div className="grid grid-cols-4 gap-1 md:gap-2 mt-1 md:mt-2 relative z-10">
                <div className="col-span-1 bg-blue-100 rounded-lg flex items-center justify-center text-sas-primary-dark text-xs md:text-base p-2 md:p-4">
                  <p className="font-medium md:font-bold">
                    &lt; 18.5 kg/m2
                    <br />
                    Underweight
                  </p>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-1 md:gap-2">
                  <div
                    className={`text-center rounded-lg flex items-center justify-center text-xs md:text-base p-2 font-medium md:p-4 cursor-pointer border border-sas-primary-dark ${
                      bmiCategory === 'underweight' &&
                      waistCategory === '<94cm/<80cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('underweight', '<94cm/<80cm')}
                  >
                    <p>Underweight</p>
                  </div>
                  <div
                    className={`text-center rounded-lg flex items-center justify-center text-xs md:text-base p-2 font-medium md:p-4 cursor-pointer border border-sas-primary-dark ${
                      bmiCategory === 'underweight' &&
                      waistCategory === '94-102cm/80-88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('underweight', '94-102cm/80-88cm')}
                  >
                    <p>Underweight</p>
                  </div>
                  <div
                    className={`text-center  rounded-lg flex items-center justify-center text-xs md:text-base p-2 font-medium md:p-4 cursor-pointer border border-sas-primary-dark ${
                      bmiCategory === 'underweight' &&
                      waistCategory === '>102cm/>88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('underweight', '>102cm/>88cm')}
                  >
                    <p>Underweight</p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-1 md:gap-2 mt-1 md:mt-2 relative z-10">
                <div className="col-span-1 bg-blue-200 p-2 md:p-4 rounded-lg flex items-center justify-center text-sas-primary-dark text-xs md:text-base">
                  <p className="font-medium md:font-bold text-center">
                    18.5-24.9 kg/m2
                    <br />
                    Healthy Weight
                  </p>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-1 md:gap-2 text-sas-primary-dark ">
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base font-medium p-2 md:p-4 border border-sas-primary-dark ${
                      bmiCategory === 'healthy' && waistCategory === '<94cm/<80cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('healthy', '<94cm/<80cm')}
                  >
                    <p>No increased risk</p>
                  </div>
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base font-medium p-2 md:p-4 border border-sas-primary-dark ${
                      bmiCategory === 'healthy' &&
                      waistCategory === '94-102cm/80-88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('healthy', '94-102cm/80-88cm')}
                  >
                    <p>No increased risk</p>
                  </div>
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base font-medium p-2 md:p-4 border border-sas-primary-dark ${
                      bmiCategory === 'healthy' &&
                      waistCategory === '>102cm/>88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('healthy', '>102cm/>88cm')}
                  >
                    <p>Increased risk</p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-1 md:gap-2 mt-1 md:mt-2 relative z-10">
                <div className="col-span-1 bg-blue-300 p-2 md:p-4 rounded-lg flex items-center justify-center text-sas-primary-dark text-xs md:text-base">
                  <p className="font-medium md:font-bold text-center">
                    25-29.9 kg/m2
                    <br />
                    Overweight
                  </p>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-1 md:gap-2 text-sas-primary-dark">
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 font-medium border border-sas-primary-dark ${
                      bmiCategory === 'overweight' &&
                      waistCategory === '<94cm/<80cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('overweight', '<94cm/<80cm')}
                  >
                    <p>No increased risk</p>
                  </div>
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 font-medium border border-sas-primary-dark ${
                      bmiCategory === 'overweight' &&
                      waistCategory === '94-102cm/80-88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('overweight', '94-102cm/80-88cm')}
                  >
                    <p>Increased risk</p>
                  </div>
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 font-medium border border-sas-primary-dark ${
                      bmiCategory === 'overweight' &&
                      waistCategory === '>102cm/>88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('overweight', '>102cm/>88cm')}
                  >
                    <p>High risk</p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-1 md:gap-2 mt-1 md:mt-2 relative z-10">
                <div className="col-span-1 bg-blue-400 p-2 md:p-4 rounded-lg text-white flex items-center justify-center text-xs md:text-base">
                  <p className="font-medium md:font-bold text-center">
                    30-34.9 kg/m2
                    <br />
                    Obese
                  </p>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-1 md:gap-2 text-sas-primary-dark">
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 font-medium border border-sas-primary-dark ${
                      bmiCategory === 'obese' && waistCategory === '<94cm/<80cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('obese', '<94cm/<80cm')}
                  >
                    <p>Increased risk</p>
                  </div>
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 font-medium border border-sas-primary-dark ${
                      bmiCategory === 'obese' &&
                      waistCategory === '94-102cm/80-88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('obese', '94-102cm/80-88cm')}
                  >
                    <p>High risk</p>
                  </div>
                  <div
                    className={`text-center cursor-pointer rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 font-medium border border-sas-primary-dark ${
                      bmiCategory === 'obese' && waistCategory === '>102cm/>88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('obese', '>102cm/>88cm')}
                  >
                    <p>Very high risk</p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-1 md:gap-2 mt-1 md:mt-2 relative z-10">
                <div className="col-span-1 bg-blue-500 rounded-lg text-white flex items-center justify-center text-xs md:text-base p-2 md:p-4">
                  <p className="font-medium md:font-bold text-center">
                    &gt; 34.9 kg/m2
                    <br />
                    Very Obese
                  </p>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-1 md:gap-2 text-sas-primary-dark">
                  <div
                    className={`text-center rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 cursor-pointer font-medium border border-sas-primary-dark ${
                      bmiCategory === 'veryObese' &&
                      waistCategory === '<94cm/<80cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('veryObese', '<94cm/<80cm')}
                  >
                    <p>Very high risk</p>
                  </div>
                  <div
                    className={`text-center rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 cursor-pointer font-medium border border-sas-primary-dark ${
                      bmiCategory === 'veryObese' &&
                      waistCategory === '94-102cm/80-88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('veryObese', '94-102cm/80-88cm')}
                  >
                    <p>Very high risk</p>
                  </div>
                  <div
                    className={`text-center rounded-lg flex items-center justify-center text-xs md:text-base p-2 md:p-4 cursor-pointer font-medium border border-sas-primary-dark ${
                      bmiCategory === 'veryObese' &&
                      waistCategory === '>102cm/>88cm'
                        ? 'bg-sas-primary-dark text-white'
                        : 'bg-white hover:bg-sas-primary-hover'
                    }`}
                    onClick={() => handleClick('veryObese', '>102cm/>88cm')}
                  >
                    <p>Very high risk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden px-4">
              <div className="absolute inset-y-0 left-0 w-1/2 h-full flex items-center justify-center overflow-hidden z-0">
                <div className="w-full h-full bg-sas-primary rounded-br-full"></div>
              </div>
              <div className="relative mt-4 md:mt-8">
                <h2 className="font-fatfrank text-lg md:text-2xl text-white">
                  Blood Pressure
                </h2>
                <div className="mt-2 rounded-lg border border-blue-200 md:flex items-center justify-normal">
                  <div className="bg-sas-primary-hover rounded-t-md md:rounded-l-md sm:rounded-t-none   p-4 font-montserrat text-sas-primary-dark text-xs md:text-base">
                    If there is too much pressure in your blood vessels, it puts
                    extra strain on your arteries and heart, which can lead to
                    serious health conditions such as heart attack, heart failure,
                    kidney disease, stroke, or dementia.
                  </div>
                  <input
                    type="text"
                    className="h-20 md:h-24  w-full md:w-52 rounded-b-md md:rounded-none px-4"
                    value={bloodPressure}
                    onChange={(e) => setBloodPressure(e.target.value)}
                  />
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bloodpressureuk.org"
                  className="flex items-center mt-1"
                >
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-white"
                  />{' '}
                  <span className="text-sas-primary-dark pl-2 text-xs md:text-sm font-montserrat">
                    www.bloodpressureuk.org
                  </span>
                </a>
              </div>

              <div className="relative mt-4 md:mt-8">
                <h2 className="font-fatfrank text-lg md:text-2xl text-white">
                  Pulse
                </h2>
                <div className="mt-2 rounded-lg border border-blue-200 md:flex items-center justify-normal">
                  <div className="bg-sas-primary-hover rounded-t-md md:rounded-l-md sm:rounded-t-none p-4 font-montserrat text-sas-primary-dark text-xs md:text-base">
                    A pulse reading checks the rhythm of your heart and can assist
                    in detecting any irregular heart rhythms. If left untreated
                    Atrial Fibrillation can increase the risk of stroke, heart
                    failure and other heart-related problems. Treatment and
                    improving lifestyle is important.
                  </div>
                  <input
                    type="text"
                    className="h-20 md:h-24 w-full md:w-52 px-4"
                    value={pulse}
                    onChange={(e) => setPulse(e.target.value)}
                  />
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bhf.org.uk/informationsupport/tests/checking-your-pulse"
                  className="flex items-center mt-1"
                >
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-white"
                  />{' '}
                  <span className="text-sas-primary-dark pl-2 text-xs md:text-sm font-montserrat">
                    www.bhf.org.uk/informationsupport/tests/checking-your-pulse
                  </span>
                </a>
              </div>
            </div>
            <div className="px-4">
              <div className="relative mt-4 md:mt-8">
                <div className="flex items-center">
                  <h2 className="font-fatfrank text-lg md:text-2xl text-sas-primary pr-2 md:pr-4 ">
                    Urinalysis
                  </h2>{' '}
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-sas-primary"
                  />{' '}
                  <span className="text-sas-primary-dark pl-2 text-xs md:text-sm font-montserrat">
                    Urine can be a useful indicator of a person’s health
                  </span>
                </div>
                <div className="grid md:grid-cols-2 gap-4 text-sas-primary-dark font-montserrat">
                  <div className="mt-2 rounded-lg border border-slate-300 flex items-center justify-normal h-32 md:h-38">
                    <div className="bg-sas-secondary-light rounded-l-md h-full px-4 flex items-center w-full">
                      <div>
                        <div className="font-fatfrank text-lg md:text-xl">
                          Blood
                        </div>
                        <div className="text-xs md:text-sm">
                          Blood should not normally be present in urine.
                        </div>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="h-full font-semibold text-xl font-montserrat w-32 px-4"
                      value={blood}
                      onChange={(e) => setBlood(e.target.value)}
                    />
                  </div>
                  <div className="mt-2 rounded-lg border border-slate-300 flex items-center justify-normal h-32 md:h-38">
                    <div className="bg-sas-secondary-light rounded-l-md h-full p-4 text-sas-primary-dark flex items-center w-full">
                      <div>
                        <div className="font-fatfrank text-lg md:text-xl">
                          Glucose
                        </div>
                        <div className="text-xs md:text-sm">
                          Can indicate the onset of diabetes. Glucose is not
                          normally excreted through urine.
                        </div>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="h-full font-semibold text-xl font-montserrat w-32 px-4"
                      value={glucose}
                      onChange={(e) => setGlucose(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-4 rounded-lg border border-slate-300 flex items-center justify-normal font-montserrat">
                  <div className="bg-sas-secondary-light rounded-l-md py-8 px-4 md:py-6 font-fatfrank text-lg md:text-2xl text-sas-primary-dark">
                    Additional Results
                  </div>
                  <textarea
                    name=""
                    id=""
                    rows="4"
                    className="w-full h-full px-4 font-semibold text-sas-primary-dark font-montserrat text-lg"
                    value={additionalResults}
                    onChange={(e) => setAdditionalResults(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="overflow-hidden px-4 relative z-10">
              <div className="mt-8">
                <h2 className="font-fatfrank text-lg md:text-2xl text-sas-primary">
                  Cholesterol
                </h2>
                <div className="mt-2 rounded-lg border border-blue-200 md:flex items-center justify-normal">
                  <div className="bg-sas-primary-hover rounded-t-md md:rounded-l-md sm:rounded-t-none p-4 font-montserrat text-sas-primary-dark  text-xs md:text-base">
                    Cholesterol is a fat that is carried around your body in the
                    blood. It is vital that you have enough of it, for your body
                    to work properly. Too much cholesterol can cause your arteries
                    to become blocked, increasing your risk of heart disease and
                    stroke.
                  </div>
                  <input
                    type="text"
                    className="h-20 md:h-24 w-full md:w-52 px-4 font-semibold text-sas-primary-dark font-montserrat text-lg"
                    value={cholesterol}
                    onChange={(e) => setCholesterol(e.target.value)}
                  />
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://heartuk.org.uk/cholesterol/overview"
                  className="flex items-center mt-1"
                >
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-sas-primary"
                  />{' '}
                  <span className="text-sas-primary-dark pl-2 text-xs md:text-sm font-montserrat">
                    www.heartuk.org.uk/cholesterol/overview
                  </span>
                </a>
              </div>

              <div className="relative my-8">
                <h2 className="font-fatfrank text-lg md:text-2xl text-sas-primary">
                  Alcohol
                </h2>
                <div className="mt-2 rounded-lg border border-blue-200 md:flex items-center justify-normal">
                  <div className="bg-sas-primary-hover rounded-t-md md:rounded-l-md sm:rounded-t-none p-4 font-montserrat text-sas-primary-dark text-xs md:text-base">
                    It is recommended that men and women do not regularly drink
                    more than 14 units a week. You should spread your drinking
                    over three days or more if you drink as much as 14 units a
                    week.
                  </div>
                  <div className="flex flex-col items-center h-32 md:h-20">
                    <label
                      htmlFor=""
                      className="font-fatfrank text-sas-primary-dark text-sm text-center"
                    >
                      <span>Weekly Unit Intake</span>
                      <input
                        type="text"
                        className="h-16 px-4 md:w-52 font-semibold text-sas-primary-dark font-montserrat text-lg"
                        value={weeklyUnitIntake}
                        onChange={(e) => setWeeklyUnitIntake(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nhs.uk/live-well/alcohol-advice/the-risks-of-drinking-too-much"
                  className="flex items-center mt-1"
                >
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-sas-primary"
                  />{' '}
                  <span className="text-sas-primary-dark pl-2 text-xs md:text-sm font-montserrat">
                    www.nhs.uk/live-well/alcohol-advice/the-risks-of-drinking-too-much
                  </span>
                </a>
              </div>

              <div className="relative my-8">
                <h2 className="font-fatfrank text-lg md:text-2xl text-sas-primary">
                  Physical Activity
                </h2>
                <div className="mt-2 rounded-lg border border-blue-200 md:flex items-center justify-normal">
                  <div className="bg-sas-primary-hover rounded-t-md md:rounded-l-md sm:rounded-t-none p-4 font-montserrat text-sas-primary-dark text-xs md:text-base">
                    Adults should do some type of physical activity every day.
                    Adults should aim to do strength exercises and moderate
                    intensity activity spread over 4 to 5 days. You should also
                    limit the time you spend lying or sitting down.
                  </div>
                  <div className="flex flex-col items-center h-32 md:h-20">
                    <label
                      htmlFor=""
                      className="font-fatfrank text-sas-primary-dark text-sm"
                    >
                      Weekly Hours
                    </label>
                    <input
                      type="text"
                      className="h-20 px-4 md:w-52 font-semibold text-sas-primary-dark font-montserrat text-lg"
                      value={weeklyHours}
                      onChange={(e) => setWeeklyHours(e.target.value)}
                    />
                  </div>
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nhs.uk/live-well/exercise/exercise-guidelines"
                  className="flex items-center mt-1"
                >
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-sas-primary"
                  />{' '}
                  <span className="text-sas-primary-dark pl-2 text-xs md:text-sm font-montserrat">
                    www.nhs.uk/live-well/alcohol-advice/the-risks-of-drinking-too-much
                  </span>
                </a>
              </div>
              <div className="relative my-8">
                <h2 className="font-fatfrank text-lg md:text-2xl text-sas-primary">
                  Smoker Status
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                  <label htmlFor="non-smoker">
                    <div className="mt-2 rounded-lg border-2 border-blue-200 flex items-center justify-normal px-4 py-2">
                      <span className=" text-sas-primary font-semibold text-sm flex items-center justify-normal py-2">
                        <input
                          type="radio"
                          name="smokerStatus"
                          value="Non Smoker"
                          className="h-5 w-5 border-2 border-sas-primary text-sas-primary focus:ring-sas-primary"
                          checked={smokerStatus === 'Non Smoker'}
                          onChange={(e) => setSmokerStatus(e.target.value)}
                          id="non-smoker"
                        />
                        <span className="pl-3">Non Smoker</span>
                      </span>
                    </div>
                  </label>
                  <label htmlFor="daily-smoker">
                    <div className="mt-2 rounded-lg border-2 border-blue-200 px-4 py-2 flex items-center justify-normal">
                      <span
                        htmlFor=""
                        className="text-sas-primary font-semibold text-sm flex items-center justify-normal py-2"
                      >
                        <input
                          type="radio"
                          name="smokerStatus"
                          value="Daily"
                          id="daily-smoker"
                          className="h-5 w-5 border-2 border-sas-primary text-sas-primary focus:ring-sas-primary"
                          checked={smokerStatus === 'Daily'}
                          onChange={(e) => setSmokerStatus(e.target.value)}
                        />
                        <span className="pl-3">Daily</span>
                      </span>
                    </div>
                  </label>
                  <label htmlFor="social-smoker">
                    <div className="mt-2 rounded-lg border-2 border-blue-200  px-4 py-2 flex items-center justify-normal">
                      <span
                        htmlFor=""
                        className=" text-sas-primary font-semibold text-sm flex items-center justify-normal py-2"
                      >
                        <input
                          type="radio"
                          name="smokerStatus"
                          value="Socially"
                          className="h-5 w-5 border-2 border-sas-primary text-sas-primary focus:ring-sas-primary"
                          checked={smokerStatus === 'Socially'}
                          onChange={(e) => setSmokerStatus(e.target.value)}
                          id="social-smoker"
                        />
                        <span className="pl-3">Socially</span>
                      </span>
                    </div>
                  </label>
                  <label htmlFor="vapour-only">
                    <div className="mt-2 rounded-lg border-2 border-blue-200  px-4 py-2 flex items-center justify-normal">
                      <span
                        htmlFor=""
                        className="text-sas-primary font-semibold text-sm flex items-center justify-normal py-2"
                      >
                        <input
                          type="radio"
                          name="smokerStatus"
                          value="Vapour Only"
                          className="h-5 w-5 border-2 border-sas-primary text-sas-primary focus:ring-sas-primary"
                          checked={smokerStatus === 'Vapour Only'}
                          onChange={(e) => setSmokerStatus(e.target.value)}
                          id="vapour-only"
                        />
                        <span className="pl-3 ">Vapour Only</span>
                      </span>
                    </div>
                  </label>
                  <label htmlFor="ex-smoker">
                    <div className="mt-2 rounded-lg border-2 border-blue-200 px-4 py-2 flex items-center justify-normal">
                      <span
                        htmlFor=""
                        className="text-sas-primary font-semibold text-sm flex items-center justify-normal py-2"
                      >
                        <input
                          type="radio"
                          name="smokerStatus"
                          value="Ex-smoker"
                          id="ex-smoker"
                          className="h-5 w-5 border-2 border-sas-primary text-sas-primary focus:ring-sas-primary"
                          checked={smokerStatus === 'Ex-smoker'}
                          onChange={(e) => setSmokerStatus(e.target.value)}
                        />
                        <span className="pl-3">Ex-smoker</span>
                      </span>
                    </div>
                  </label>
                </div>
              </div>
              <div className="relative mt-8 border border-sas-primary-hover rounded-lg">
                <h2 className="font-fatfrank text-lg md:text-2xl text-sas-primary-dark bg-sas-primary-hover rounded-t-md p-4">
                  Additional Notes
                </h2>
                <div className="flex flex-col items-center h-24">
                  <textarea
                    name=""
                    id=""
                    className="w-full rounded-b-md px-4 font-semibold text-sas-primary-dark font-montserrat text-lg"
                    rows="10"
                    value={additionalNotes}
                    onChange={(e) => setAdditionalNotes(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="flex items-center mt-1 font-medium">
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className="text-sas-primary "
                />{' '}
                <span className="text-sas-primary-dark pl-2 text-xs md:text-sm font-montserrat">
                  Following your health screen please consider how making some
                  changes to your lifestyle (however small) may assist in reducing
                  future chronic health conditions such as heart disease, diabetes
                  and dementia.
                </span>
              </div>
            </div>
            <div className="relative z-10 px-4">
              <div className="relative mt-4 z-10">
                <div
                  htmlFor=""
                  className="text-sas-primary  font-semibold text-xl font-fatfrank"
                >
                  Email
                </div>
                <input
                  type="email"
                  className="px-4 border-2 border-sas-primary-hover focus:ring-sas-primary rounded-lg w-full py-1 font-semibold text-sas-primary-dark font-montserrat text-lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="off"
                />
              </div>
              <div className=" mt-4 font-medium">
                <span className="text-sas-primary-dark mt-8 block text-xs font-montserrat">
                  Make a Difference (UK) Ltd (MAD) uses anonymised data for
                  statistical and research purposes aimed at identifying broad
                  regional health trends among educators.
                </span>
                <span className="text-sas-primary-dark mt-4 block text-xs font-montserrat">
                  Our lawful bases for this processing under the UK GDPR are:
                  <ul className="list-disc list-inside">
                    <li>for our legitimate interests (Art. 6 (1)(f), and;</li>
                    <li>for statistical and research purposes (Art. 9(2)(j)</li>
                  </ul>
                  If you do not wish your health data to be used for these
                  purposes please tick the box below to opt out of the study. For
                  more information about how MAD uses personal data please see the
                  <a
                    href="https://schoolwellbeing.com/privacy"
                    className="underline ml-1"
                  >
                    Privacy Notice
                  </a>
                </span>
                <span className="text-sas-primary-dark mt-8 block text-xs font-montserrat">
                  <span className="pr-2">
                    <input
                      type="checkbox"
                      className="h-3 w-3 border-2 border-sas-primary text-sas-primary focus:ring-sas-primary"
                      checked={optOut}
                      id="opt-out"
                      onChange={(e) => setOptOut(e.target.checked)}
                    />
                  </span>
                  <label className="inline" htmlFor="opt-out">
                    Tick this box if you do not want MAD to include your
                    anonymised health data in its research projects.
                  </label>
                </span>
              </div>
              <div className="flex justify-center relative z-10">
                <button
                  className="bg-sas-primary w-full mt-8 hover:bg-sas-primary-dark text-white font-fatfrank py-2.5 px-4 rounded-lg focus:outline-none focus:shadow-outline text-center"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
            {/* <div className="absolute bottom-0 z-0">
              <img src={wave} alt="base wave" className="overflow-hidden" />
            </div> */}
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>

  )
}

export default App
