import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [pin, setPin] = useState("")
  const navigate = useNavigate()
  const correctPin = "1994"

  const handleLogin = (e) => {
    e.preventDefault()

    if (pin === correctPin) {
      localStorage.setItem("isAuthenticated", "true");
      navigate("/dashboard")
    } else {
      alert("Incorrect PIN. Please try again.")
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="bg-white p-6 md:p-10 shadow-lg rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-sas-primary">Login</h2>
          <button className="text-sas-primary">
            <FontAwesomeIcon icon={faRightToBracket} className="text-2xl" />
          </button>
        </div>

        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-semibold">Enter PIN</label>
            <input
              type="password"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sas-primary"
              placeholder="Enter your PIN"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-sas-primary text-white py-2 rounded-md font-semibold hover:bg-sas-primary-hover transition"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
