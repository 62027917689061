import React, { useState, useEffect } from "react";
import axios from './axiosInstance'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'

const Dashboard = () => {
  const [unsentForms, setUnsentForms] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUnsentForms = () => {
      const savedForms = JSON.parse(localStorage.getItem("healthScreenData")) || []
      setUnsentForms(savedForms)
    }

    fetchUnsentForms()

    window.addEventListener("storage", fetchUnsentForms)

    return () => {
      window.removeEventListener("storage", fetchUnsentForms)
    };
  }, [])

  const handleSaveAll = async () => {
    if (!navigator.onLine) {
      alert("No internet connection. Try again later.")
      return
    }

    if (unsentForms.length === 0) {
      alert("No unsent forms to save.")
      return
    }

    const savePromises = unsentForms.map(async (form, index) => {
      try {
        const response = await axios.post("health-screen-report/create", form)

        if (response.status === 200) {
          console.log(`Form ${index + 1} saved successfully.`)
          return { status: "fulfilled" };
        } else {
          throw new Error(`Form ${index + 1} failed`)
        }
      } catch (error) {
        console.error(`Error sending form ${index + 1}:`, error)
        return { status: "rejected", error }
      }
    })

    const results = await Promise.allSettled(savePromises)
    const failedForms = unsentForms.filter((_, index) => results[index].status === "rejected")

    if (failedForms.length === 0) {
      Swal.fire({
        title: 'All forms Sent!',
        text: 'The health screen results have been submitted.',
        icon: 'success',
      })
      localStorage.removeItem("healthScreenData")
      setUnsentForms([]);
    } else {
      Swal.fire({
        title: 'Error Occured!',
        text: 'There was an error submitting your results, please try again',
        icon: 'error',
      })
      localStorage.setItem("healthScreenData", JSON.stringify(failedForms))
      setUnsentForms(failedForms)
    }
  }

  return (
    <div className="relative container mx-auto p-4">
      <button className="absolute top-0 right-0 rounded-lg px-4 py-2 bg-sas-primary text-white hover:bg-blue-700 mt-4 mr-4"
        onClick={() => navigate("/")}>
        Back to Health Screening
      </button>
      <div className="mx-auto max-w-4xl mt-8">
        <h2 className="text-2xl font-bold text-sas-primary mb-4">Unsent Health Screenings</h2>

        {unsentForms.length === 0 ? (
          <p className="text-gray-600">No unsent forms available.</p>
        ) : (
          <>
            <ul className="border p-4 rounded-md bg-white shadow-md">
              {unsentForms.map((form, index) => (
                <li key={index} className="p-2 border-b last:border-b-0">
                  <strong>Name:</strong> {form.name}, <strong>Age:</strong> {form.age}, <strong>Email:</strong> {form.email}
                </li>
              ))}
            </ul>

            <button
              onClick={handleSaveAll}
              className="mt-4 bg-sas-primary text-white py-2 px-4 rounded-md font-semibold hover:bg-sas-primary-hover transition"
            >
              Save All
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
