import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from "./Routes";
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './service-worker';
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals()
serviceWorker.register()
